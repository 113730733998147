/* Implement custom javascript here */
jQuery(document).ready(function(){

    jQuery('.page-galeria-hanganyagok .gallery-title-area').matchHeight();

    jQuery('.view-partnereink .view-grouping-header').click(function(){
        jQuery(this).children('.arrow-down').toggleClass('slide-clicked');
        jQuery(this).next().slideToggle();
    });

    setTimeout(function(){
        jQuery('body').addClass('loaded');
    }, 3000);

    checkUrls();

    // TagCloud
    jQuery("#tagCloud a").tagcloud({
        size: {start: 12, end: 20, unit: "px"},
        color: {start: '#595858', end: '#595858'}
    });
    
    jQuery(".user-picture img").click(function(){
        jQuery(".author-tooltip").toggleClass('hide');
    });
             
    jQuery(".osztondijas_image img").click(function(){
        var obj = jQuery(this).closest('li');

        obj.siblings().attr('class', 'inactive').end().toggleClass('inactive osztondijas_expanded');
       
    });   
});

function checkUrls(){
    var url = window.location.href;

    if (url.indexOf("/kozossegepitok/") >= 0) {
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("kozossegepitok") >= 0){ jQuery(this).addClass('active'); }
        });

    }
    if (url.indexOf("/igy-elunk/") >= 0) {
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("igy-elunk") >= 0){ jQuery(this).addClass('active'); }
        });

    }

    if (url.indexOf("/kviz/") >= 0) {
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("kviz") >= 0){ jQuery(this).addClass('active'); }
        });

    }

    if (url.indexOf("/esemenynaptar/") >= 0) {
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("esemenynaptar") >= 0){ jQuery(this).addClass('active'); }
        });

    }

    if (url.indexOf("/partnereink/") >= 0) {
        jQuery('#main-menu li a').each(function(){
            if(jQuery(this).attr('href').indexOf("partnereink") >= 0){ jQuery(this).addClass('active'); }
        });

    }

    return true;
}

(function ($, Drupal) {

    Drupal.behaviors.partybus = {
        attach: function(context, settings) {

            function collage() {
                $('.Collage').removeWhitespace().collagePlus({
                    'fadeSpeed'     : 2000,
                    'targetHeight'  : 150,
                    'effect' : 'effect-1',
                    'allowPartialLastRow' : true
                });
            }


            var resizeTimer = null;
            $(window).bind('resize', function() {
                // hide all the images until we resize them
                // set the element you are scaling i.e. the first child nodes of ```.Collage``` to opacity 0
                $('.Collage .Image_Wrapper').css("opacity", 0);
                // set a timer to re-apply the plugin
                if (resizeTimer) clearTimeout(resizeTimer);
                resizeTimer = setTimeout(collage, 200);
            });

        }
    };

})(jQuery, Drupal);